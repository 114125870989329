const toggleVideo = function (container) {
  this.node = container;

  this.handleClick = (e) => {
    e.preventDefault();
      this.video.play();
      this.video.setAttribute('data-state', 'playing');
  };

  this.handlePause = () => {
      this.video.setAttribute('data-state', 'paused');
  };

  this.init = () => {
    this.video = this.node.parentNode.querySelector('video');
    if (this.video) {
      this.node.addEventListener('click', this.handleClick, false);
      this.video.addEventListener('pause', this.handlePause, false);
    }
  };
  this.destroy = () => {
      this.node.removeEventListener('click', this.handleClick);
      this.video.removeEventListener('pause', this.handlePause, false);
  };
};

export default toggleVideo;
