const toggleVideoSound = function (container) {
    this.node = container;

    this.handleClick = (e) => {
        e.preventDefault();

        if (!this.video.muted) {
            this.video.muted = true;
            this.mute.setAttribute('hidden', '');
            this.unmute.removeAttribute('hidden');
            this.video.removeAttribute('data-manually-unmuted');
        } else {
            this.video.muted = false;
            this.video.setAttribute('data-manually-unmuted', '');
            this.unmute.setAttribute('hidden', '');
            this.mute.removeAttribute('hidden');
        }
    };

    this.init = () => {
        this.video = this.node.parentNode.querySelector('video');
        this.mute = this.node.querySelector('[data-mute]');
        this.unmute = this.node.querySelector('[data-unmute]');

        this.node.addEventListener('click', this.handleClick, false);
    };
    this.destroy = () => {
        this.node.removeEventListener('click', this.handleClick);
    };
};

export default toggleVideoSound;
