import buildThresholdList from '../utils/buildThresoldList';

// softVideo behavior controls the play/pause state of inline video elements
// playing when in viewport, paused otherwise
// if the node element is not a <video>, it dispatches a play/pause event

const HIDDEN_CLASS = 's-hidden';
const FADE_IN_CLASS = `s-fade-in`;
export const PLAY_EVENT = 'softvideo:play';
export const PAUSE_EVENT = 'softvideo:pause';

const pauseEvent = new Event(PAUSE_EVENT);
const playEvent = new Event(PLAY_EVENT);

const canPlay = (element) => {
  return getComputedStyle(element, null).display !== 'none';
};

const A17 = window.A17 || {};

const softVideo = function (container) {
  this.node = container;

  this.observe = (node) => {
    if (typeof A17.softVideoObserver === 'undefined') {
      const threshold = buildThresholdList();
      let options = {
        root: null, rootMargin: '0px', threshold,
      };

      A17.softVideoObserver = new IntersectionObserver(((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.intersectionRatio >= 0.1 && canPlay(entry.target)) {
              if (entry.target && entry.target.nodeName === 'VIDEO') {
                if (entry.target.paused === true && !entry.target.hasAttribute('data-manually-paused')) {
                  entry.target.play();
                }
              } else {
                entry.target.dispatchEvent(playEvent);
              }
            } else {
              if (entry.target && entry.target.nodeName === 'VIDEO') {
                if (entry.target.paused === false) {
                   entry.target.pause();
                }
              } else {
                entry.target.dispatchEvent(pauseEvent);
              }
            }
          }
        });
      }));
    }
    A17.softVideoObserver.observe(node);
  };

  this.hide = () => {
    this.pause();
    this.node.classList.add(HIDDEN_CLASS);
  };

  this.show = () => {
    this.node.classList.remove(HIDDEN_CLASS);
  };

  this.setVisibility = () => {
    if (canPlay(this.node)) {
      this.show();
    } else {
      this.hide();
    }
  };

  this.onAnimationEnd = () => {
    if (this.node.style.opacity === 0) {
      this.node.style.visibilty = 'hidden';
    } else {
      this.node.style.visibilty = 'visible';
    }
  };

  this.init = () => {
    this.observe(this.node);
    this.setVisibility();
    setTimeout(() => {
      this.node.classList.add(FADE_IN_CLASS);
    }, 250);
    this.node.addEventListener('animationend', this.onAnimationEnd);
  };
  this.mediaQueryUpdated = () => {
    this.setVisibility();
  };
  this.destroy = () => {
    this.node.removeEventListener('animationend', this.onAnimationEnd);
    A17.softVideoObserver.unobserve(this.node);
  };
};

export default softVideo;
