const carouselMedia = function (container) {
  this.node = container;
  this.timing = Number(this.node.dataset.timing || 4000);

  this.animate = () => {
    this.currentIndex = (this.currentIndex + 1);
    if (this.currentIndex >= this.items.length) {
      this.currentIndex = 0;
    }

    this.items.forEach((item, index) => {
      item.classList.remove('is-active');
      const video = item.querySelector('video');
      if (video) {
        video.pause();
      }

      if (index === this.currentIndex) {
        item.classList.add('is-active');
        if (video) {
          video.play();
        }
      }
    });

    this.timeout = setTimeout(this.animate, this.timing);
  };

  this.IOCallback = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.animate();
      } else {
        clearTimeout(this.timeout);
      }
    });
  };

  this.init = () => {
    this.items = Array.from(this.node.querySelectorAll('[data-carousel-media-item]') || []);
    this.currentIndex = 0;
    this.timeOut = null;
    this.IO = new IntersectionObserver(this.IOCallback);
    this.IO.observe(this.node);
  };

  this.destroy = () => {
    clearTimeout(this.timeout);
    this.IO.unobserve(this.node);
    this.IO.disconnect();
  };
};

export default carouselMedia;
