import { purgeProperties } from '@area17/a17-helpers';
import { scrollToY, triggerCustomEvent } from '@area17/a17-helpers';
import { supportsPushState, replaceState, getURLWithoutAnchor } from '../utils/pushState';
import hasSearchQuery from '../utils/hasSearchQuery';
import setAriaAttribute from '../utils/aria';

var scrollToSection = function(container) {
  const bts = container.querySelectorAll('a');
  const navbarScroller = document.querySelector('[data-navbar-scroller]');
  // const btPrevious = document.querySelector('button[data-previous-section]');
  // const btNext = document.querySelector('button[data-next-section]');
  const activeKlass = ['theme-link', 'link-active'];
  const topOffset = 80;
  let ticking = false;
  let lastScrollPos = 0;
  let currentSection = -1;

  // Scroll the navabr to make sure the current section is always visible
  function _scrollNavbar() {
    const activeBt = window.A17.sections[currentSection].bt;

    if(activeBt && navbarScroller) {
      const viewportOffset = activeBt.getBoundingClientRect();
      const top = viewportOffset.top;

      if (top < 0) {
        navbarScroller.scrollTop = 0;
      } else if (top < 100) {
        navbarScroller.scrollTop = navbarScroller.scrollTop - 100;
      } else if (top > window.innerHeight - (80 + 20)) {
        navbarScroller.scrollTop = Math.max(0, navbarScroller.scrollTop + top - window.innerHeight + 80 + 20);
      }
    }
  }

  // Update current section in the navigation
  function _updateCurrentSection(initialSection) {
    let sectionToActivate = initialSection || 0;
    const updateUrl = initialSection ? false : true;

    window.A17.sections.forEach(function (section, index) {
      const isActive = (section.posY) < lastScrollPos; // - topOffset
      if (isActive && index > 0) {
        sectionToActivate = index;
      }
    });

    // new Section
    if(currentSection !== sectionToActivate) {
      // deactivate sections
      window.A17.sections.forEach(function (section, index) {
        section.active = false;
        if(section.bt) {
          section.bt.classList.remove(...activeKlass);
          setAriaAttribute(section.bt, false);
        }
      });

      // highlight active section
      window.A17.sections[sectionToActivate].active = true;
      const activeBt = window.A17.sections[sectionToActivate].bt;
      if(activeBt) {
        activeBt.classList.add(...activeKlass);
        setAriaAttribute(activeBt, true);

        // highlight the parent section if any
        if(activeBt.hasAttribute('data-parent')) {
          const parentActiveBt = container.querySelector('a[href="' + activeBt.getAttribute('data-parent') + '"]');
          if(parentActiveBt) {
            parentActiveBt.classList.add(...activeKlass);
            setAriaAttribute(parentActiveBt, true);
          }
        }
      }

      // update url
      if(updateUrl) {
        const anchor = window.A17.sections[sectionToActivate].id ? '#' + window.A17.sections[sectionToActivate].id : '';
        const url = getURLWithoutAnchor() + anchor;
        replaceState(url);
      }

      // save current section
      currentSection = sectionToActivate;
      _scrollNavbar();
      // _setPrevNextButton();
    }
  }

  // Get sections
  function _getSections() {
    window.A17.sections = [];

    // First section is the top of the page
    window.A17.sections.push({
      el : null,
      bt : null,
      id : '',
      posY : 0,
      active : false
    });

    [].map.call(bts, function(bt){
      const section = {};
      const id = bt.getAttribute('href').substr(1);
      const el = document.getElementById(id) ? document.getElementById(id) : null;
      section.el = el;
      section.bt = bt;
      section.id = id;
      section.posY = el ? el.getBoundingClientRect().top + window.pageYOffset : 0;
      section.active = false;

      window.A17.sections.push(section);
    });
  }

  // Scroll event based on requestAnimationFrame
  function _scroll() {
    lastScrollPos = window.pageYOffset;

    if (!ticking) {
      window.requestAnimationFrame(function () {
        if(!window.A17.navbar) {
          _updateCurrentSection();
        }

        ticking = false;
      });
    }

    ticking = true;
  }

  // Animate vertical scroll
  function _animateScroll(position) {
    const posY = position + 1;

    window.A17.scrolling = true;

    _getSections();
    window.removeEventListener('scroll', _scroll);

    function _animateScrollComplete() {
      lastScrollPos = window.pageYOffset;
      _updateCurrentSection();
      window.addEventListener('scroll', _scroll);

      window.A17.scrolling = false;
    }

    // if we need to move or are we already in palce
    if(window.pageYOffset === posY) {
      _animateScrollComplete();
    } else {
      // Animate scroll to position
      scrollToY({
        offset: posY,
        easing: 'easeOut',
        onComplete: _animateScrollComplete
      });
    }
  }

  // Click to Scroll
  function _handleClicks(event) {
    event.preventDefault();

    const bt = event.currentTarget;
    bt.blur();

    if (window.A17.scrolling) {
      return;
    }

    const id = bt.getAttribute('href').substr(1); // dont forget to remove the #
    const target = document.getElementById(id);

    function _handleClicksAnimate() {
      const position = target.getBoundingClientRect().top + window.pageYOffset;
      if(!window.A17.scrolling) {
        _animateScroll(position);
      }
    }

    if(target) {
      if(window.A17.navbar) {
        triggerCustomEvent(document, 'navbar:hide');
        triggerCustomEvent(document, 'navbarSearchResults:hide');
        setTimeout(_handleClicksAnimate, 100); // 100ms timeout so the navbar is hidden on smaller screen before starting to scroll
      } else {
        _handleClicksAnimate();
      }
    }
  }

  // Scroll to a defined index
  function _scrollTo(event) {
    if(window.A17.scrolling) {
      return;
    }

    if(event.data) {
      if(event.data.hasOwnProperty('index')) {
        const index = event.data.index;

        if(window.A17.sections[index]) {
          const position = window.A17.sections[index].posY;
          _animateScroll(position);
        }
      }
    }
  }

  // function _setPrevNextButton() {
  //   if(btPrevious) {
  //     if(currentSection <= 0) btPrevious.disabled = true;
  //     else btPrevious.disabled = false;
  //   }

  //   if(btNext) {
  //     if(currentSection >= window.A17.sections.length - 1) btNext.disabled = true;
  //     else btNext.disabled = false;
  //   }
  // }

  function _handleResized() {
    if(!window.A17.navbar) {
      _getSections();
      _updateCurrentSection();
      _scrollNavbar();
    }
  }

  function _init() {
    // If there is a query let's open the search (search.js and toggleSearch.js)
    if (hasSearchQuery() && !window.A17.searched) {
      return false;// Search is not active already
    }
    if (bts.length === 0) {
      return false;
    }

    window.A17.scrolling = false;

    // reset sections
    window.A17.sections = [];

    [].map.call(bts, function(bt) {
      bt.addEventListener('click', _handleClicks, false);
    });

    window.addEventListener('scroll', _scroll);
    document.addEventListener('resized', _handleResized);
    document.addEventListener('scroll:section', _scrollTo, false);

    // init
    const currentSectionSlug = window.PREVIEW_SLUG || window.location.href.replace(getURLWithoutAnchor() + '#', '');

    let initialSection = 0;

    _getSections();

    window.A17.sections.forEach(function (section, index) {
      if(section.id === currentSectionSlug) {
        initialSection = index;
      }
    });

    _updateCurrentSection(initialSection);

    triggerCustomEvent(document, 'scroll:section', {
      index: initialSection
    });
  }

  this.destroy = function() {
    if (bts.length > 0) {
      // remove specific event handlers
      [].map.call(bts, function(bt){
        bt.removeEventListener('click', _handleClicks, false);
      });

      window.removeEventListener('scroll', _scroll);
      document.removeEventListener('resized', _handleResized);
      document.removeEventListener('scroll:section', _scrollTo, false);

      // reset sections
      window.A17.sections = [];
    }

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    _init();
  };
};

export default scrollToSection;
