const toggleVideo = function (container) {
  this.node = container;

  this.handleClick = (e) => {
    e.preventDefault();

    if (this.video.paused) {
      this.video.play();
      this.play.setAttribute('hidden', '');
      this.pause.removeAttribute('hidden');
      this.video.removeAttribute('data-manually-paused');
    } else {
      this.video.pause();
      this.video.setAttribute('data-manually-paused', '');
      this.pause.setAttribute('hidden', '');
      this.play.removeAttribute('hidden');
    }
  };

  this.init = () => {
    this.video = this.node.parentNode.querySelector('video');
    this.play = this.node.querySelector('[data-play]');
    this.pause = this.node.querySelector('[data-pause]');

    this.node.addEventListener('click', this.handleClick, false);
  };
  this.destroy = () => {
      this.node.removeEventListener('click', this.handleClick);
  };
};

export default toggleVideo;
